import './HeadCard.css';

const HeadCard = () => {

  return (
    <div id="head_card" className="head-card">
      <div className="welcome-row">
        <div className="space-graphic-div"></div>
        <div className="content-div">
          <div className="big-text">
            Welcome
          </div>
          <div className="normal-text">
            to the world of innovation and problem-solving!
          </div>
        </div>
      </div>

      <div className="description-row">
        <div className="para-text">
          As a seasoned software developer, I thrive on turning complex ideas into elegant, functional, and user-centric solutions.
          <br /><br />
          Lets embark on a trans-formative journey together, where possibilities are limitless, and the future is only a few lines of code away.
        </div>
      </div>

    </div>
  )
};

export default HeadCard;