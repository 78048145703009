import './KnowHow.css';
import spanSeparator1 from '../assets/spanSeparator1.svg';
import spanSeparator2 from '../assets/spanSeparator2.svg';
import spanSeparator3 from '../assets/spanSeparator3.svg';
import spanSeparator4 from '../assets/spanSeparator4.svg';


const KnowHow = () => {

  return (
    <div id="know_how" className="know-how">
      <div className='title-row'>
        <div className='big-text'>
          Know-how
        </div>
        <div className='small-text-box'>
          <div className='small-text'>
            If you are struggling with any of these?
            <br/>
            Then allow me to assist you.
          </div>
          <div className='smile-text'>
            :)
          </div>
        </div>
      </div>


      <div className='know-how-content-row'>

        {/* row one */}
        <div className='know-how-list-1'>
          <div className='know-how-separator'>
            <img src={spanSeparator1} alt='' />
          </div>

          <div className='know-how-item'>
            Software / Web Development
          </div>

          <div className='know-how-item'>
            UI/UX Designing
          </div>

          <div className='know-how-item'>
            QA
          </div>

          <div className='know-how-item'>
            Automation & PLC Programming 
          </div>

          <div className='know-how-item'>
            Custom Object Modeling / Designing
          </div>

          <div className='know-how-item'>
            Industrial Drawings / Layouts 
          </div>

        </div>

        {/* row two */}
        <div className='know-how-list-2'>
          <div className='know-how-item'>
            3D Printing 
          </div>

          <div className='know-how-item'>
            Machining Custom Tool 
          </div>

          <div className='know-how-item'>
            Gear Cutting
          </div>

          <div className='know-how-item'>
            CNC
          </div>

          <div className='know-how-item'>
            VMC
          </div>

          <div className='know-how-item'>
            Induction Hardening
          </div>

          <div className='know-how-item'>
            And Much More...
          </div>

          <div className='know-how-separator'>
            <img src={spanSeparator4} alt=''/>
          </div>

        </div>

      </div>

    </div>
  )
};

export default KnowHow;