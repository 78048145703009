import thumbImg from '../assets/profileImage200.svg';
import './HeaderCard.css';


const HeaderCard = () => {

  return (
    <div id="header_card" className="header-card">

      <div className="right-div">
        <div className="name-div">
          <div className="name-text">
            Nitesh Jha
          </div>
          <div className="desig-text">
            Software Developer
          </div>
        </div>

        <div className="profile-image-div">
          <img src={thumbImg} alt="Nitesh Jha" className="profile-image" />
        </div>
      </div>

    </div>
  )
};

export default HeaderCard;