import './FooterCard.css';

const FooterCard = () => {

  return (
    <div id="footer_card" className="footer-card">

    </div>
  )
};

export default FooterCard;