import emailIcon from '../assets/emailIcon.svg';
import whatsappIcon from '../assets/whatsappIcon.svg';
import './JoinMe.css';

const JoinMe = () => {

  return (
    <div id="join_me" className="join-me">
      <div className='title-row'>
        <div className='big-text'>
          Join me
        </div>
        <div className='small-text-box'>
          <div className='small-text'>
            and experience the power of innovation through seamless
            <br/>
            and sophisticated software development.
          </div>
        </div>
      </div>

      <div className='detaied-row'>
        <div className='note-col'>
          <div className='open-quote-mark'>“</div>
          <div className='note-text'>
            Are you a Student who needs assistance for school/college projects/presentations?
            <br/><br/>
            Then I can arrange most of these services at minimal cost for you! “
          </div>
        </div>

        <div className='contact-col'>
          <div className='contact-title'>
            I am available on
          </div>
          <div className='contact-email'>
            <img src={emailIcon} alt="Nitesh Jha" className="email-icon" />
            info@niteshjha.com
          </div>
          <div className='contact-number'>
            <img src={whatsappIcon} alt="Nitesh Jha" className="number-icon" />
            +91 9403031118
          </div>
        </div>
      </div>

    </div>
  )
};

export default JoinMe;