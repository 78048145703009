// import thumbImg from './assets/profileImage200.svg';
// import profilePicImg from './assets/profileImage.png';
import './App.css';
import HeaderCard from './components/HeaderCard';
import HeadCard from './components/HeadCard';
import KnowHow from './components/KnowHow';
import JoinMe from './components/JoinMe';
import AboutMe from './components/AboutMe';
import FooterCard from './components/FooterCard';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <HeaderCard />
        {/* <img src={thumbImg} className="" alt=" Nitesh Jha" />
        <img src={profilePicImg} className="" alt="Nitesh Jha" /> */}
      </header>
      <HeadCard />
      <KnowHow />
      <JoinMe />
      <AboutMe />
      <FooterCard />
    </div>
  );
}

export default App;
